export enum ApiPortalPermissionItem {
  ACCOUNTING_TRANSACTION_ITEM_STATUS_EDIT = 'ACCOUNTING_TRANSACTION_ITEM_STATUS_EDIT',
  ACCOUNTING_TRANSACTION_PRICING_EDIT = 'ACCOUNTING_TRANSACTION_PRICING_EDIT',
  ACCOUNTING_TRANSACTION_REFRESH_EDIT = 'ACCOUNTING_TRANSACTION_REFRESH_EDIT',
  ACCOUNTING_TRANSACTION_REPORT_EXECUTE = 'ACCOUNTING_TRANSACTION_REPORT_EXECUTE',
  ACCOUNTING_TRANSACTION_STATUS_EDIT = 'ACCOUNTING_TRANSACTION_STATUS_EDIT',
  ACCOUNTING_TRANSACTION_VIEW = 'ACCOUNTING_TRANSACTION_VIEW',
  ACTIVITY_BULK_UPLOAD_APPROVE_EDIT = 'ACTIVITY_BULK_UPLOAD_APPROVE_EDIT',
  ACTIVITY_BULK_UPLOAD_EDIT = 'ACTIVITY_BULK_UPLOAD_EDIT',
  ACTIVITY_CHR_SYNC = 'ACTIVITY_CHR_SYNC',
  CAMPUS_EDIT = 'CAMPUS_EDIT',
  CAMPUS_VIEW = 'CAMPUS_VIEW',
  CHARTER_PORTAL_TRIP_INVOICE_HISTORY_VIEW = 'CHARTER_PORTAL_TRIP_INVOICE_HISTORY_VIEW',
  CHARTER_TRIP_BOOKING_AMOUNT_VIEW = 'CHARTER_TRIP_BOOKING_AMOUNT_VIEW',
  CHARTER_TRIP_BOOKING_EDIT = 'CHARTER_TRIP_BOOKING_EDIT',
  CHARTER_TRIP_DETAILS_VIEW = 'CHARTER_TRIP_DETAILS_VIEW',
  CHARTER_TRIP_EXPORT_EDIT = 'CHARTER_TRIP_EXPORT_EDIT',
  CHARTER_TRIP_INVOICE_EDIT = 'CHARTER_TRIP_INVOICE_EDIT',
  CHARTER_TRIP_PRICING_VIEW = 'CHARTER_TRIP_PRICING_VIEW',
  CHARTER_TRIP_RIDE_VIEW = 'CHARTER_TRIP_RIDE_VIEW',
  CHARTER_TRIP_STATUS_EDIT = 'CHARTER_TRIP_STATUS_EDIT',
  CHARTER_TRIP_STATUS_LOG_VIEW = 'CHARTER_TRIP_STATUS_LOG_VIEW',
  CHARTER_TRIP_VIEW = 'CHARTER_TRIP_VIEW',
  CHARTER_VENDOR_ASSIGNMENT_EDIT = 'CHARTER_VENDOR_ASSIGNMENT_EDIT',
  DASHBOARD_VIEW = 'DASHBOARD_VIEW',
  DISPATCH_CONTROL_CENTER_VIEW = 'DISPATCH_CONTROL_CENTER_VIEW',
  DISTRICT_EDIT = 'DISTRICT_EDIT',
  DISTRICT_PROGRAM_EDIT = 'DISTRICT_PROGRAM_EDIT',
  DISTRICT_VIEW = 'DISTRICT_VIEW',
  DRIVER_BUS_REPORT_APPROVE_EDIT = 'DRIVER_BUS_REPORT_APPROVE_EDIT',
  DRIVER_BUS_REPORT_APPROVE_VERIFY = 'DRIVER_BUS_REPORT_APPROVE_VERIFY',
  DRIVER_BUS_REPORT_EDIT = 'DRIVER_BUS_REPORT_EDIT',
  DRIVER_BUS_REPORT_RAW_OCR_EDIT = 'DRIVER_BUS_REPORT_RAW_OCR_EDIT',
  DRIVER_BUS_REPORT_VIEW = 'DRIVER_BUS_REPORT_VIEW',
  DRIVER_DOCUMENT_EDIT = 'DRIVER_DOCUMENT_EDIT',
  DRIVER_DOCUMENT_EXPIRATION_VIEW = 'DRIVER_DOCUMENT_EXPIRATION_VIEW',
  DRIVER_DOCUMENT_VIEW = 'DRIVER_DOCUMENT_VIEW',
  DRIVER_EDIT = 'DRIVER_EDIT',
  DRIVER_LOGIN_EDIT = 'DRIVER_LOGIN_EDIT',
  DRIVER_STATUS_EDIT = 'DRIVER_STATUS_EDIT',
  DRIVER_VIEW = 'DRIVER_VIEW',
  END_OF_DAY_INSPECTION_VIEW = 'END_OF_DAY_INSPECTION_VIEW',
  END_OF_RIDE_INSPECTION_VIEW = 'END_OF_RIDE_INSPECTION_VIEW',
  FIREBASE_CACHE_EDIT = 'FIREBASE_CACHE_EDIT',
  GEOTAB_DASHBOARD_VIEW = 'GEOTAB_DASHBOARD_VIEW',
  INTER_YARD_MANAGEMENT_EDIT = 'INTER_YARD_MANAGEMENT_EDIT',
  MANUAL_RIDE_TAG_EDIT = 'MANUAL_RIDE_TAG_EDIT',
  MONITOR_EDIT = 'MONITOR_EDIT',
  MONITOR_VIEW = 'MONITOR_VIEW',
  PARENT_ROUTE_NOTIFICATION_MAILERS = 'PARENT_ROUTE_NOTIFICATION_MAILERS',
  PARENT_ROUTE_NOTIFICATION_SEND = 'PARENT_ROUTE_NOTIFICATION_SEND',
  PUBLISH_EXPLORER_ROUTE = 'PUBLISH_EXPLORER_ROUTE',
  REPORT_STUDENT_RFID_EXECUTE = 'REPORT_STUDENT_RFID_EXECUTE',
  REPORT_VIEW = 'REPORT_VIEW',
  RIDE_ASSIGNMENT_EDIT = 'RIDE_ASSIGNMENT_EDIT',
  RIDE_BULK_NOTIFICATIONS = 'RIDE_BULK_NOTIFICATIONS',
  RIDE_VENDOR_ASSIGNMENT_HISTORY_VIEW = 'RIDE_VENDOR_ASSIGNMENT_HISTORY_VIEW',
  RIDE_VIEW = 'RIDE_VIEW',
  ROUTE_EXPLORER_IMPORT_PDF_EDIT = 'ROUTE_EXPLORER_IMPORT_PDF_EDIT',
  ROUTE_EXPLORER_VIEW = 'ROUTE_EXPLORER_VIEW',
  ROUTE_EXPLORER_EDIT = 'ROUTE_EXPLORER_EDIT',
  ROUTE_EXPLORER_VIOLATION_EDIT = 'ROUTE_EXPLORER_VIOLATION_EDIT',
  ROUTE_EXPLORER_VIOLATION_VIEW = 'ROUTE_EXPLORER_VIOLATION_VIEW',
  ROUTE_GROUP_APPROVE_EDIT = 'ROUTE_GROUP_APPROVE_EDIT',
  ROUTE_GROUP_CHANGE_EDIT = 'ROUTE_GROUP_CHANGE_EDIT',
  ROUTE_GROUP_CREATE_EDIT = 'ROUTE_GROUP_CREATE_EDIT',
  ROUTE_GROUP_VIEW = 'ROUTE_GROUP_VIEW',
  ROUTE_MOCK_RIDE_CREATE = 'ROUTE_MOCK_RIDE_CREATE',
  ROUTE_SHEET_PRINT = 'ROUTE_SHEET_PRINT',
  ROUTE_CALENDAR_EDIT = 'ROUTE_CALENDAR_EDIT',
  ROUTE_VIEW = 'ROUTE_VIEW',
  SCHOOL_EMPLOYEE_EDIT = 'SCHOOL_EMPLOYEE_EDIT',
  SCHOOL_EMPLOYEE_PERMISSION_EDIT = 'SCHOOL_EMPLOYEE_PERMISSION_EDIT',
  SCHOOL_EMPLOYEE_VIEW = 'SCHOOL_EMPLOYEE_VIEW',
  SCHOOL_SUPPORT_IMPERSONATION_EXECUTE = 'SCHOOL_SUPPORT_IMPERSONATION_EXECUTE',
  STANDBY_DRIVER_EDIT = 'STANDBY_DRIVER_EDIT',
  STANDBY_DRIVER_VIEW = 'STANDBY_DRIVER_VIEW',
  STUDENT_ASSIGN_VENDOR_EDIT = 'STUDENT_ASSIGN_VENDOR_EDIT',
  STUDENT_CHANGE_REQUEST_SELF_REQUEST_VIEW = 'STUDENT_CHANGE_REQUEST_SELF_REQUEST_VIEW',
  STUDENT_EDIT = 'STUDENT_EDIT',
  STUDENT_PARENT_ACCOUNT_ADMIN_EDIT = 'STUDENT_PARENT_ACCOUNT_ADMIN_EDIT',
  STUDENT_PARENT_ACCOUNT_EDIT = 'STUDENT_PARENT_ACCOUNT_EDIT',
  STUDENT_PARENT_ACCOUNT_VIEW = 'STUDENT_PARENT_ACCOUNT_VIEW',
  STUDENT_RFID_DISTRICT_CONFIG_VIEW = 'STUDENT_RFID_DISTRICT_CONFIG_VIEW',
  STUDENT_RFID_DOWNLOAD_EDIT = 'STUDENT_RFID_DOWNLOAD_EDIT',
  STUDENT_RFID_ORDER_EDIT = 'STUDENT_RFID_ORDER_EDIT',
  STUDENT_RFID_ORDER_VIEW = 'STUDENT_RFID_ORDER_VIEW',
  STUDENT_RFID_WORKQUEUE_EDIT = 'STUDENT_RFID_WORKQUEUE_EDIT',
  STUDENT_VIEW = 'STUDENT_VIEW',
  TIMESHEET_ENTRY_EDIT = 'TIMESHEET_ENTRY_EDIT',
  TIMESHEET_ENTRY_VIEW = 'TIMESHEET_ENTRY_VIEW',
  TRANSPORTATION_REGISTRATION_BUS_LOOKUP_VIEW = 'TRANSPORTATION_REGISTRATION_BUS_LOOKUP_VIEW',
  TRANSPORTATION_REGISTRATION_EDIT = 'TRANSPORTATION_REGISTRATION_EDIT',
  TRANSPORTATION_REGISTRATION_VIEW = 'TRANSPORTATION_REGISTRATION_VIEW',
  USER_PERMISSION_EDIT = 'USER_PERMISSION_EDIT',
  VEHICLE_EDIT = 'VEHICLE_EDIT',
  VEHICLE_INSPECTION_ASSESSMENT_EDIT = 'VEHICLE_INSPECTION_ASSESSMENT_EDIT',
  VEHICLE_INSPECTION_MECHANIC_ASSIGNMENT_EDIT = 'VEHICLE_INSPECTION_MECHANIC_ASSIGNMENT_EDIT',
  VEHICLE_INSPECTION_VIEW = 'VEHICLE_INSPECTION_VIEW',
  VEHICLE_VIEW = 'VEHICLE_VIEW',
  VENDOR_EMPLOYEE_EDIT = 'VENDOR_EMPLOYEE_EDIT',
  VENDOR_EMPLOYEE_PERMISSION_EDIT = 'VENDOR_EMPLOYEE_PERMISSION_EDIT',
  VENDOR_EMPLOYEE_VIEW = 'VENDOR_EMPLOYEE_VIEW',
  VENDOR_SUPPORT_IMPERSONATION_EXECUTE = 'VENDOR_SUPPORT_IMPERSONATION_EXECUTE',
  VENDOR_VIEW = 'VENDOR_VIEW',
  WORK_QUEUE_VIEW = 'WORK_QUEUE_VIEW',
  YARD_VIEW = 'YARD_VIEW',
  PARKOUT_EDIT = 'PARKOUT_EDIT',
  PARKOUT_VIEW = 'PARKOUT_VIEW',
  PARKOUT_EVENT_APPROVE_EDIT = 'PARKOUT_EVENT_APPROVE_EDIT',
  PARKOUT_EVENT_VIEW = 'PARKOUT_EVENT_VIEW',
  DRIVER_COMMUNICATION_VIEW = 'DRIVER_COMMUNICATION_VIEW',
  DRIVER_COMMUNICATION_EDIT = 'DRIVER_COMMUNICATION_EDIT',
  DRIVER_COMMUNICATION_APPROVE_EDIT = 'DRIVER_COMMUNICATION_APPROVE_EDIT',
  DRIVER_ATTENDANCE_VIEW = 'DRIVER_ATTENDANCE_VIEW',
  DRIVER_ATTENDANCE_EDIT = 'DRIVER_ATTENDANCE_EDIT',
  DISTRICT_BULK_NOTIFICATIONS = 'DISTRICT_BULK_NOTIFICATIONS',
  RIDE_ATTENDANCE_EDIT = 'RIDE_ATTENDANCE_EDIT',
  SSO_CONFIG_EDIT = 'SSO_CONFIG_EDIT',
}
